import { CostData } from "atoms/CostToCarbonData";
import React, { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';
import config from "config";
import ThirdReport from "./ThirdReport";
import { useRecoilValue, useRecoilState } from "recoil";
import { CCPolyCostOfCo2PerPartFData } from "atoms/CCPolymer/CCPolyCo2Process";
import { GET_CLIENT_EMAILS } from "app/view/CCStepsMetal/components/ProcessMetal/components/getStepDetail";
import { useApolloClient } from "@apollo/react-hooks";
import { useGetMachineImage } from "./components/getMachineImage";
import * as htmlToImage from "html-to-image";
import ProductionScenario from "app/components/ProductionScenario";
import {
  VisualDonut,
  JobsPerWeek,
  TotalJobs,
  SingleJobTime,
  MachineImage,
  CCPolyworkingWeeksPerYear,
  HighestIndexArray,
  WorkingHours

} from "atoms/General/index.js";
import { EditText } from "react-edit-text";
import { HotspotArray } from "atoms/General";
import Co2InReports from "app/components/Co2InCostReports/Co2InReports";
import HotspotTable from "app/view/HotspotTable";
import ConsultingTeam from "app/components/ConsultingTeam";

const axios = require("axios").default;

const SecondReport = ({
  staticData,
  PreviousScenarioState,
  ScenarioState,
  isWorkingPerJobEditable,
  isHotSpotAvailable
}) => {
  const client = useApolloClient();
  const [totalJobs, setTotalJobs] = useRecoilState(TotalJobs);
  const [singleJobTime, setSingleJobTime] = useRecoilState(SingleJobTime);
  const [machineImage, setMachineimage] = useRecoilState(MachineImage);
  const [workingWeeksPerYear, setWorkingWeeksPerYear] = useRecoilState(
    CCPolyworkingWeeksPerYear
  );
  const [hotspotArray, setHotSpotArray] = useRecoilState(HotspotArray)

  const jobYear = workingWeeksPerYear * totalJobs;
  const groupid = parseInt(sessionStorage.getItem("group_id"));
  const [clientSteps, setClientSteps] = useState([]);
  const region = sessionStorage.getItem("currency");
  const [email, setEmail] = useState();
  // Getting Currency Symbol...
  const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
  const currency = sessionStorage.getItem("currency");
  const obj = currencies.find((x) => x.name === currency);

  const [cppData, setCppData] = useState([]);
  const [cppSum, setcppSum] = useState(0);
  const costOfCo2pp = useRecoilValue(CCPolyCostOfCo2PerPartFData);
  const visualDonut = useRecoilValue(VisualDonut);
  const jobsPerWeek = useRecoilValue(JobsPerWeek);
  const highestIndexArray = useRecoilValue(HighestIndexArray)
  const workingHours = useRecoilValue(WorkingHours)

  const [psImage, setPsImage] = useState();
  const [psData, setpsData] = useState();
  const d = useGetMachineImage(staticData?.machine_id?.value);
  setMachineimage(d);
  const pageSize = 3
  const access_token = sessionStorage.getItem("access_token");
  let hightlightKey = ""
  highestIndexArray.forEach(item => hightlightKey = Object.keys(item).reduce((prevValue, currValue) => item[prevValue] > item[currValue] ? prevValue : currValue))


  const getHotSpotAnalysis = async (pageNo) => {
    try {
      const response = await axios.default.get(`${config.serviceUrl}hotspot/analysis/user/list`, {
        params: {
          pageSize: pageSize,
          pageNo: pageNo,
          technology_id: 1
        },
        headers: {
          Authorization: access_token,

        }
      })

      setHotSpotArray(response.data.data)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setpsData((prevState) => {
      return {
        ...prevState,
        type: "Polymer",
        setupTime: +staticData?.setup_time?.value,
        buildTime: +staticData?.build_time?.value,
        unpackingtime: +staticData?.unpacking_time_per_job?.value,
        coolDownTime: +staticData?.cool_down_time_in_system?.value,
        isOnlyWorkingHours: false,
      };
    });
    setTimeout(() => {
      var node = document.getElementById("productionschedule");

      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          setPsImage(dataUrl);
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    }, 2000);
    const data = Object.fromEntries(
      Object.entries(staticData).filter(([key]) => key.includes("cpp_part_"))
    );
    const dataArray = Object.values(data);
    setCppData(dataArray);
    for (let index = 0; index < dataArray.length; index++) {

      setcppSum(
        (value) => {
          return (value + +dataArray[index].value)
        }
      )
    }
    getHotSpotAnalysis(0)
  }, []);

  useEffect(() => {
    client
      .query({
        query: GET_CLIENT_EMAILS,
        variables: { group_id: groupid },
      })
      .then(({ data, loading }) => {
        setClientSteps(data.getRegionsByUserGroup);
      })
      .catch((error) => {
        console.log("error in process", error);
        // toast.error(error.graphQLErrors[0].messasge.message)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for (let index = 0; index < clientSteps.length; index++) {
      if (clientSteps[index].name === region) {
        setEmail(clientSteps[index].email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSteps]);

  const getjobsWeek = (jobs) => {
    setTotalJobs(jobs);
  };
  const getTurnAroundTime = (time) => {
    setSingleJobTime(time);
  };

  return (
    <div className="reports-section">
      {/* TODO.... */}
      {/* <div className="powder-container pb-0">
        <div className="powder-heading-top head-padding">
          <p className="powder-heading">Post Processing</p>
          <p className="powder-heading text-align-end">{
            staticData?.post_processing_cost_per_part?.value
              ?
              <>{`${staticData?.post_processing_cost_per_part?.value} ${obj.currency_symbol}`}</>
              :
              ""
          }
          </p>

        </div>
      </div> */}
      {/* Cost per Part */}
      <div className="powder-container">
        <div className="powder-heading-top head-padding">
          <p className="powder-heading">Cost per part</p>
          <p className="powder-heading text-align-end">
            {
              staticData?.total_am_cpp?.value ? `${(staticData?.total_am_cpp?.value).toFixed(2)} ${obj.currency_symbol}`
                :
                ""
            }
          </p>

        </div>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">System cost per part</p>
          </span>
          <span>
            <p className="output-heading">
              {staticData?.system_cost_per_part?.value ? (
                <>{`${staticData?.system_cost_per_part?.value} ${obj.currency_symbol}`}</>
              ) : (
                "Not Available"
              )}{" "}
            </p>
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Recurring cost per part</p>
          </span>
          <span>
            <p className="output-heading">
              {staticData?.recurring_cost_per_part?.value ? (
                <>{`${staticData?.recurring_cost_per_part?.value} ${obj.currency_symbol}`}</>
              ) : (
                "Not Available"
              )}{" "}
            </p>
          </span>
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Material cost per part</p>
          </span>
          <span>
            <p className="output-heading">
              {staticData?.material_cost_per_part?.value ? (
                <>{`${staticData?.material_cost_per_part?.value} ${obj.currency_symbol}`}</>
              ) : (
                "Not Available"
              )}{" "}
            </p>
          </span>
        </div>

        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Consumables per part</p>
          </span>
          <span>
            <p className="output-heading">
              {staticData?.cpp_consumables?.value ? (
                <>{`${staticData?.cpp_consumables?.value} ${obj.currency_symbol}`}</>
              ) : (
                "Not Available"
              )}{" "}
            </p>
          </span>
        </div>

        {
          costOfCo2pp &&
          <>
            <Co2InReports costOfCo2pp={costOfCo2pp} currency={obj.currency_symbol} />

          </>
        }

      </div>
      {/* CPP PART ID EXISTS */}
      {cppData !== undefined && cppData.length > 1 && (
        <div className="powder-container">
          <div className="powder-heading-top head-padding">
            <p className="powder-heading">Multiple parts</p>
          </div>
          {cppData !== undefined &&
            cppData.map((data, index) => {
              return (
                <>
                  <div
                    className="d-flex justify-content-between pl-4 pr-4"
                    key={index}
                  >
                    <span>
                      <p className="input-heading">{`Cost Per Part ${index + 1
                        }`}</p>
                    </span>
                    <span>
                      <p className="output-heading">
                        {data.value}&nbsp; {obj.currency_symbol}
                      </p>
                    </span>
                  </div>
                  <i className="pl-4 pr-4">
                    <hr />
                  </i>
                </>
              );
            })}
        </div>
      )}
      {/* Production Scenarios */}
      <div className="powder-container">
        <div className="powder-heading-top head-padding">
          <p className="powder-heading">Production Scenarios</p>
        </div>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Builds per week</p>
          </span>
          <span>
            <p className="output-heading">
              {totalJobs == null ? "Not Available" : totalJobs}
            </p>
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Working weeks per year</p>
          </span>
          {isWorkingPerJobEditable === null ? (
            <span>
              <p className="output-heading">
                <EditText
                  name="working-weeks-per-year"
                  style={{ fontSize: "16px", border: "1px solid #ccc" }}
                  value={workingWeeksPerYear}
                  onChange={(event) =>
                    setWorkingWeeksPerYear(event.target.value)
                  }
                />
              </p>
            </span>
          ) : (
            <span>
              <p className="output-heading">
                {workingWeeksPerYear == null
                  ? "Not Available"
                  : workingWeeksPerYear}
              </p>{" "}
            </span>
          )}
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Jobs per year</p>
          </span>
          <span>
            <p className="output-heading">
              {jobYear == null ? "Not Available" : Math.round(jobYear)}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Throughput per year</p>
          </span>
          <span>
            <p className="output-heading">
              {Math.trunc(+(jobYear * +staticData?.no_of_parts?.value))}
            </p>
          </span>
        </div>

        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Turnaround time</p>
          </span>
          <span>
            <p className="output-heading">{`${singleJobTime} h`}</p>{" "}
          </span>
        </div>

        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Operators needed per machine</p>
          </span>
          <span>
            <p className="output-heading">{+staticData?.number_of_operators?.value}</p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Number of shifts</p>
          </span>
          <span>
            <p className="output-heading">{
              workingHours === "true" ? 1 : 3
            }</p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Operator’s time needed per job</p>
          </span>
          <span>
            <p className="output-heading">{+staticData?.unpacking_time_per_job?.value + +staticData?.setup_time?.value} h</p>{" "}
          </span>
        </div>
      </div>
      <h2 className="mt-5">Weekly Production Scheduling Scenario</h2>

      <ProductionScenario
        getjobsWeek={getjobsWeek}
        staticData={psData}
        setStaticData={setpsData}
        getTurnAroundTime={getTurnAroundTime}
        type="Polymer"
      />

      {
        isHotSpotAvailable ? '' : <HotspotTable hotspotArray={hotspotArray} hightlightKey={hightlightKey} />
      }




      <div className="d-flex justify-content-center align-items-center">
        <button
          onClick={() => PreviousScenarioState()}
          className="selection-button step-button"
        >
          Previous
        </button>
        <ThirdReport
          cppData={cppData}
          staticData={staticData}
          currency={obj.currency_symbol}
          costOfCo2pp={costOfCo2pp}
          visualDonut={visualDonut}
          jobsPerWeek={jobsPerWeek}
          totalJobs={totalJobs}
          singleJobTime={singleJobTime}
          machineImage={machineImage}
          psImage={psImage}
          cppSum={cppSum}
          hotspotArray={hotspotArray}
          hightlightKey={hightlightKey}
          isHotSpotAvailable={isHotSpotAvailable}
        />
        <div className="d-flex justify-content-center align-items-center">
          <ConsultingTeam navigate={email} />
        </div>
      </div>
    </div>
  );
};

export default SecondReport;
